import { ChevronRightMinor } from '@shopify/polaris-icons';
import { Button, Link } from '../../../../imb-react';
import styles from './Policies.module.scss';

import { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { classNames } from '../../../../imb-react/utilities/css';
import { Order } from '../../../../types';
import { getPolicyExpirationDate } from '../../../../utils/Common';

export function Policies() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [policies, setPolicies] = useState<any[]>([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const fetchUserPolicies = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/user/policies', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          setPolicies(data.data);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserPolicies();
  }, []);

  const emptyMarkup = (
    <div className={styles.PoliciesBody}>
      <div className={styles.PoliciesEmpty__box}>
        <div className={styles.PoliciesEmpty__text}>
          <p>Non hai polizze attive</p>
        </div>
        <div className={styles.PoliciesEmpty__box__icon}>
          <img src="/icon_add.svg" alt="Empty policies" />
        </div>
        <div className={styles.PoliciesEmpty__box__button}>
          <Link url="/products/other" imbDarkLow>
            Aggiungi le tue polizze attive
          </Link>
        </div>
      </div>
    </div>
  );

  const renderStatus = (date_end: Date | undefined) => {
    if (!date_end) {
      return { expiring: true, message: 'Data di scadenza non disponibile' };
    }

    const today = new Date();
    const expirationDate = new Date(date_end);

    if (expirationDate < today) {
      return { expiring: true, message: 'Scaduta' };
    } else {
      return { expiring: false, message: 'Attiva' };
    }
  };

  const cardsPerPage = 3;
  const cardWidth = 250;
  const totalCards = policies.length;

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => Math.max(prevSlide - 1, 0));
  };

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => Math.min(prevSlide + 1, totalCards - cardsPerPage));
  };

  const getContainerWidth = () => {
    return totalCards * cardWidth + 'px';
  };

  const getTransformValue = () => {
    const translateValue = -currentSlide * cardWidth;
    return `translateX(${translateValue}px)`;
  };

  const policiesSliderMarkup = (
    <>
      <div className={styles.PoliciesContainer}>
        <div className={styles.PoliciesBody} style={{ transform: getTransformValue(), width: getContainerWidth() }}>
          {policies.map((order: Order, index) => {
            const expirationStatus = renderStatus(order.date_end);
            return (
              <div key={index} className={classNames(styles.PoliciesBox, order.date_end && expirationStatus.expiring ? styles.expiring : styles.active)}>
                <div className={styles.PoliciesBox__data}>
                  <div>
                    <div className={styles.PoliciesBox__title}>{order.line_items[0].name}</div>
                    <div className={`${styles.PoliciesBox__text} ${styles.PoliciesBox__status}`}>{order.date_end && expirationStatus.message}</div>
                    <div className={styles.PoliciesBox__text}>
                      scade il
                      <div className={styles.PoliciesBox__expiration}>
                        {order.date_end ? getPolicyExpirationDate(new Date(order.date_end)) : 'Data di scadenza non disponibile'}
                      </div>
                    </div>
                  </div>
                  <div className={styles.PoliciesBox__icon}>
                    <img
                      src={order.line_items[0].product.icon && process.env.REACT_APP_BLOB_IMAGES_URL + order.line_items[0].product.icon.key}
                      alt={order.line_items[0].product.icon && order.line_items[0].product.icon.title}
                    />
                  </div>
                </div>

                <div className={styles.PoliciesBox__button}>
                  <Button primary={!expirationStatus.expiring} destructive={expirationStatus.expiring} url="/products/policies">
                    Vedi dettagli
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );

  return <div>{policies.length > 0 ? policiesSliderMarkup : emptyMarkup}</div>;
}
