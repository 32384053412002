import React, { useCallback, useState } from 'react';
import { CustomersMajor, HomeMajor, ProductsMajor, NoteMajor, CalendarMajor, ChatMajor, MagicMajor, HashtagMajor } from '@shopify/polaris-icons';

import styles from './NavigationMarkup.module.scss';

import { Avatar, LegacyStack, Navigation } from '../../imb-react';
import { navigationContextControlMarkup } from '../NavigationContextControlMarkup';
import { Notification } from './components/Notification';

type NavigationMarkupProps = {
  user: any;
};

export function NavigationMarkup({ user }: NavigationMarkupProps) {
  const [navItemActive, setNavItemActive] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const toggleIsLoading = useCallback(() => setIsLoading((isLoading) => !isLoading), []);

  const location = window.location.pathname;

  // ---- Navigation ----
  const navigationMarkup = (
    <Navigation location={location} contextControl={navigationContextControlMarkup}>
      {/* Navigation header */}
      <div className={styles.NavigationHeader}>
        <div className={styles.NavigationHeader__logo}>
          <img
            src={
              user.organization_logo && user.organization_logo.length > 0 ? process.env.REACT_APP_BLOB_IMAGES_URL + user.organization_logo : `/logo_boxed_w.png`
            }
            alt="Logo"
          />
        </div>
        <div className={styles.NavigationHeader__user}>
          {/* <Text as="h1" fontWeight="bold">
            Ciao!
          </Text> */}
          <LegacyStack alignment="center" distribution="center">
            <a href="/account/profile">
              <Avatar customer name="Simone" />
            </a>
            <h3 className={styles.NavigationHeader__userName}>{user.name}</h3>
          </LegacyStack>
        </div>
      </div>
      {/* Navigation menu */}
      <Navigation.Section
        fill
        items={[
          {
            label: 'Utente',
            icon: CustomersMajor,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('account');
            },
            url: '/account',
            exactMatch: true,
          },
          {
            label: 'Bacheca',
            icon: HomeMajor,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('bacheca');
            },
            url: '/',
            exactMatch: true,
          },
          {
            label: 'Preventivi & Polizze',
            icon: NoteMajor,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('products');
            },
            url: '/products',
          },
          {
            label: 'Consulenze',
            icon: CalendarMajor,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('consultancies');
            },
            url: '/consultancies',
          },
          {
            label: 'Premio',
            icon: MagicMajor,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('rewards');
            },
            url: '/rewards',
          },
          {
            label: 'Novità',
            icon: HashtagMajor,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('alien');
            },
            url: '/news',
          },
        ]}
      />
      <Notification />
    </Navigation>
  );
  return navigationMarkup;
}
