import styles from './ConsultantCard.module.scss';
import { Button } from '../../../../imb-react';

export function ConsultantCard() {
  return (
    <div>
      <div className={styles.ConsultantCard}>
        {/* header */}
        <div className={styles.ConsultantCardHeader}>
          <div className={styles.ConsultantCardHeader__photo}>
            <img src="/consultant.svg" alt="" />
          </div>
          <div className={styles.ConsultantCardHeader__title}>
            <h5>Sebastiano Andreis</h5>
          </div>
        </div>
        {/* contactInfo */}
        <div className={styles.ConsultantCardInfo}>
          <div className={styles.ConsultantCardInfo__phone}>
            <img src="/icon_phone.svg" alt="" />
            <a href="tel:+39 123 4567 890">+39 123 4567 890</a>
          </div>
          <div className={styles.ConsultantCardInfo__email}>
            <img src="/icon_mail.svg" alt="" />
            <a href="mailto:sebastiano.andreis@ilmiobrokerassicurativo.it">sebastiano.andreis@ilmiobrokerassicurativo.it</a>
          </div>
          <div className={styles.ConsultantCardInfo__timetable}>
            <img src="/icon_timetable.svg" alt="" />
            <ul>
              <li>
                <strong>Lunedì:</strong> 17/18:30
              </li>
              <li>
                <strong>Giovedì:</strong> 17/18:30
              </li>
            </ul>
          </div>
        </div>
        {/* description */}
        <div className={styles.ConsultantCardDescription}>
          <h6>Riguardo il tuo consulente</h6>
          <p>
            Sono un consulente assicurativo esperto, dedicato a fornire consulenze personalizzate e soluzioni assicurative su misura per le esigenze uniche dei
            miei clienti.
          </p>
        </div>
        {/* cta */}
        <div className={styles.ConsultantCardCta}>
          <Button primary sizeLarge url="https://outlook.office365.com/book/PartiamodallaCyberilmiobrokerassicurativoit@nano-insurance.com/">
            Richiedi consulenza
          </Button>
        </div>
      </div>
    </div>
  );
}
